import React, { Component, Fragment } from 'react';
import Errors from './common/Errors';
import Router from './Router';

class App extends Component {
    render() {
        return (
            <Fragment>
                <Errors />
                <Router />
            </Fragment>
        );
    }
}

export default App;
