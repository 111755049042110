import ResizeObserver from 'resize-observer-polyfill';
import { XolaConfig } from 'xola-config';

let config;

try {
    config = new XolaConfig();
} catch (e) {
    // Unable to initialize xola-config library - iFrame resize might not work!
}

const resizeObserver = new ResizeObserver((entries, observer) => {
    if (config) {
        config.easyXDM.resizeFrame(document.body.scrollHeight);
    }
});

resizeObserver.observe(document.body);
