import { Field as FormikField, getIn } from 'formik';
import React, { Component } from 'react';
import classNames from 'classnames';
import './Field.scss';

class Field extends Component {
    render() {
        const { children, label, alignLabel = false, defaultValue, ...rest } = this.props;

        return (
            <FormikField {...rest}>
                {({ field, form }) => {
                    const isTouched = getIn(form.touched, field.name);
                    const error = (isTouched || form.submitCount > 0) && getIn(form.errors, field.name);
                    const value = getIn(form.values, field.name, defaultValue);

                    return (
                        <div className={classNames('form-group row', { 'align-items-center': alignLabel })}>
                            <label className="Field-label col col-form-label">
                                {label} {rest.required ? <span className="text-danger">*</span> : null}
                            </label>

                            <div className="col">
                                {children({ field: { ...field, value }, error, form })}
                                {error ? <div className="d-block invalid-feedback">{error}</div> : null}
                            </div>
                        </div>
                    );
                }}
            </FormikField>
        );
    }
}

export default Field;
