import { waiverUpdateChecked } from '../../actions/waiverActions';
import formatDate from 'date-fns/format';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuillDelta from './QuillDelta';
import _ from 'lodash';

class CheckboxVariable extends Component {
    constructor(props) {
        super(props);
        this.id = _.uniqueId('CheckboxVariable');
    }

    render() {
        return (
            <span className="custom-control custom-checkbox d-inline-block">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={this.id}
                    onChange={e => this.props.waiverUpdateChecked(e.target.checked)}
                />

                <label className="custom-control-label" htmlFor={this.id}>
                    I Agree
                </label>
            </span>
        );
    }
}

const ConnectedCheckboxVariable = connect(
    null,
    { waiverUpdateChecked },
)(CheckboxVariable);

/**
 * Renders a variable.
 */
const Variable = ({ children }) => {
    const variable = children.trim();

    if (variable === '{requiredCheckbox}') {
        return <ConnectedCheckboxVariable />;
    }

    if (variable === '{currentDate}') {
        return formatDate(new Date(), 'MM/DD/YYYY');
    }

    // Handle more variable types here.

    return variable;
};

const TemplateBodyDelta = ({ children = { ops: [] } }) => {
    return <QuillDelta renderVariable={variable => <Variable>{variable}</Variable>}>{children}</QuillDelta>;
};

export default TemplateBodyDelta;
