import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import SignaturePad from 'react-signature-pad-wrapper';
import React, { Component, createRef } from 'react';
import waiversApi from '../../api/waiversApi';

class SignatureModal extends Component {
    signaturePad = createRef();

    handleClearClick = () => {
        this.signaturePad.current.clear();
    };

    handleAccept = () => {
        if (!this.signaturePad.current.isEmpty()) {
            this.signaturePad.current.canvas.toBlob(async blob => {
                const formData = new FormData();
                formData.append('signature', blob);

                const { data } = await waiversApi.post('/signatures', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                this.props.onAccept(data.path);
            });
        }
    };

    render() {
        const { children, onAccept, toggle, title, ...rest } = this.props;

        return (
            <Modal toggle={toggle} size="lg" {...rest}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <SignaturePad ref={this.signaturePad} />

                <ModalFooter>
                    <Button color="light" onClick={this.handleClearClick}>
                        Clear
                    </Button>

                    <Button color="success" onClick={this.handleAccept}>
                        Accept
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default SignatureModal;
