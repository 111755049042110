const getParamsFromUrl = url => {
    return url
        .slice(url.indexOf('?') + 1)
        .split('&')
        .reduce((params, pair) => {
            const [key, value] = pair.split('=');
            params[key] = value;
            return params;
        }, {});
};

export default getParamsFromUrl;
