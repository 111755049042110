import React, { Component } from 'react';
import { Field as FormikField, getIn } from 'formik';
import Cleave from 'cleave.js/react';
import _ from 'lodash';
import ErrorMessage from '../form/ErrorMessage';

class DateOfBirthField extends Component {
    id = _.uniqueId('DateOfBirthField');
    render() {
        const { fieldPrefix } = this.props;
        return (
            <div>
                <label htmlFor={`${this.id}-dateOfBirth`}>Date of Birth</label>

                <FormikField name={`${fieldPrefix}.dateOfBirth`}>
                    {({ field, form }) => (
                        <Cleave
                            {...field}
                            id={`${this.id}-dateOfBirth`}
                            options={{ date: true, datePattern: ['m', 'd', 'Y'] }}
                            className="form-control"
                            placeholder="MM/DD/YYYY"
                            onChange={(e) => {
                                // overiding so we can update date of birth on questionnaire, so we don't display date of birth 2 times
                                field.onChange(e);
                                const dateFieldIndex = _.findIndex(
                                    getIn(form.values, `${fieldPrefix}.answers`),
                                    (answer) => answer.type === 'dateOfBirth',
                                );

                                if (dateFieldIndex !== -1) {
                                    form.setFieldValue(
                                        `${fieldPrefix}.answers.${dateFieldIndex}.value`,
                                        e.target.value,
                                    );
                                }
                            }}
                        />
                    )}
                </FormikField>

                <small className="text-danger">
                    <ErrorMessage name={`${fieldPrefix}.dateOfBirth`} />
                </small>
            </div>
        );
    }
}

export default DateOfBirthField;
