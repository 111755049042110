import { Field, ErrorMessage } from 'formik';
import React, { Component } from 'react';
import _ from 'lodash';
import { DEFAULT_QUESTIONNAIRE_LABELS } from '../../../constants';

class SelectField extends Component {
    id = _.uniqueId('SelectField');

    render() {
        const { fieldPrefix, field } = this.props;
        const isDefaultQuestionnaire = DEFAULT_QUESTIONNAIRE_LABELS.includes(field.label);
        return (
            <div className="form-group">
                <label
                    htmlFor={`${this.id}-${field.label}`}
                    className={`${isDefaultQuestionnaire ? '' : 'notranslate'}`}
                >
                    {field.label}
                </label>{' '}
                {field.unit ? <small className="notranslate">({field.unit})</small> : null}
                <div className="notranslate">
                    {field.description ? (
                        field.url ? (
                            <small className="d-block text-muted">
                                {' '}
                                <a href={field.url}>{field.description}</a>
                            </small>
                        ) : (
                            <small className="d-block text-muted">{field.description}</small>
                        )
                    ) : null}
                </div>
                <Field
                    as="select"
                    className="form-control"
                    id={`${this.id}-${field.label}`}
                    name={`${fieldPrefix}.value`}
                    placeholder={field.required ? 'Required' : ''}
                    validate={value => {
                        if (field.required && !value) {
                            return 'Required.';
                        }
                    }}
                >
                    <option>Select value</option>
                    {field.options.map(option => (
                        <option value={option} className="notranslate">
                            {option}
                        </option>
                    ))}
                </Field>
                <small className="text-danger">
                    <ErrorMessage name={`${fieldPrefix}.value`} />
                </small>
            </div>
        );
    }
}

export default SelectField;
