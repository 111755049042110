import TemplatePreview from './templates/TemplatePreview';
import TemplateList from './templates/TemplateList';
import TemplateForm from './templates/TemplateForm';
import ThankYou from './templates/ThankYou';
import React, { Component } from 'react';
import { Router } from '@reach/router';

class AppRouter extends Component {
    render() {
        return (
            <Router>
                <TemplateList path="/" />
                <TemplateForm path="/templates/create" />
                <TemplateForm path="/templates/:id/edit" />
                <TemplatePreview path="/templates/:id/preview" />
                <ThankYou path="/thank-you" />
            </Router>
        );
    }
}

export default AppRouter;
