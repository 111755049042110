import { Formik, Form as FormikForm, Field } from 'formik';
import React, { Component } from 'react';

class Form extends Component {
    render() {
        const { children, validator, disabled, ...rest } = this.props;

        return (
            <Formik validationSchema={validator} enableReinitialize {...rest}>
                <FormikForm>
                    <Field>
                        {({ form }) => <fieldset disabled={disabled || form.isSubmitting}>{children}</fieldset>}
                    </Field>
                </FormikForm>
            </Formik>
        );
    }
}

export default Form;
