/**
 * Get ordinal for a given number: 1 -> 1st, 2 -> 2nd, etc.
 * From: http://forums.shopify.com/categories/2/posts/29259
 * @param {number} n
 */
const getOrdinal = n => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export default getOrdinal;
