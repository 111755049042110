import { getIn, useFormikContext } from 'formik';
import React from 'react';
import CheckboxField from './Fields/CheckboxField';
import SelectField from './Fields/SelectField';
import TextAreaField from './Fields/TextAreaField';
import TextField from './Fields/TextField';

const fieldMap = {
    checkbox: CheckboxField,
    select: SelectField,
    textarea: TextAreaField,
    dateOfBirth: () => null, // No need to render since already exists in the waiver.
};

const QuestionnaireForm = ({ fieldPrefix }) => {
    const { values } = useFormikContext();
    const fields = getIn(values, fieldPrefix);

    return fields.map((field, index) => {
        const Field = fieldMap[field.type] || TextField;
        return <Field key={field.id} fieldPrefix={`${fieldPrefix}.${index}`} field={field} />;
    });
};

export default QuestionnaireForm;
