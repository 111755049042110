import loader from '../../images/loader.gif';
import React, { Component } from 'react';

class Loader extends Component {
    static defaultProps = {
        delay: 300,
    };

    state = {
        show: false,
    };

    updateShow() {
        const { show, delay } = this.props;

        if (show) {
            this.timeout = setTimeout(() => this.setState({ show: true }), delay);
        } else {
            clearTimeout(this.timeout);
            this.setState({ show: false });
        }
    }

    componentDidMount() {
        this.updateShow();
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show) {
            this.updateShow();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        return this.state.show ? <img src={loader} className={this.props.className} alt="Loader" /> : null;
    }
}

export default Loader;
