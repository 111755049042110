import _ from 'lodash';

export const getQuestionnaireFields = (state, isMinor, signedFor) => {
    const questionnaire = state.questionnaire.data;

    if (questionnaire.source !== 'plugin') {
        return [];
    }

    const fields = questionnaire.fields.filter(field => {
        return isMinor ? ['minors', 'all'].includes(field.visibility) : ['adults', 'all'].includes(field.visibility);
    });

    return _.sortBy(fields, 'seq');
};
