import TemplatePreviewSignedForToggle, { SIGNED_FOR_ADULT } from './TemplatePreviewSignedForToggle';
import { waiverSave, waiverUpdate } from '../../actions/waiverActions';
import { questionnaireLoad } from '../../actions/questionnaireActions';
import { getCheckboxCount } from '../../selectors/templatesSelectors';
import waiverValidator from '../../validators/waiverValidator';
import { templateLoad } from '../../actions/templatesActions';
import TemplateBodyDelta from '../common/TemplateBodyDelta';
import getParamsFromUrl from '../../utils/getParamsFromUrl';
import MinorsSpinnerField from './MinorsSpinnerField';
import React, { Component, Fragment } from 'react';
import getOrdinal from '../../utils/getOrdinal';
import SubmitButton from '../form/SubmitButton';
import { CustomInput } from 'reactstrap';
import { connect } from 'react-redux';
import Loader from '../common/Loader';
import Paper from '../common/Paper';
import Form from '../form/Form';
import { Field } from 'formik';
import _ from 'lodash';
import GuestFields from './GuestFields';
import { getQuestionnaireFields } from '../../selectors/questionnaireSelectors';
import { initializeLocalize } from '../../utils/localeHelper';

const LOGO_SIZE = 80;

class TemplatePreview extends Component {
    state = {
        acceptedElectronicSignatureConsent: false,
    };

    handleAcceptElectronicSignatureConsent = (e, form) => {
        form.setFieldValue('electronicSignatureConsent', e.target.checked);
        this.setState({ acceptedElectronicSignatureConsent: e.target.checked });
    };

    isAccepted() {
        return this.props.acceptedBody && this.props.questionnaire.loading === false;
    }

    componentDidMount() {
        const { orderId, itemId, sellerId, tag, experienceId } = getParamsFromUrl(window.location.href);

        if (orderId && itemId) {
            this.props.waiverUpdate({ orderId, itemId });
        }

        if (tag) {
            this.props.waiverUpdate({ tags: [tag] });
        }

        if (!this.props.template) {
            this.props.templateLoad(this.props.id);
        }

        if (_.isEmpty(this.props.questionnaire.data)) {
            this.props.questionnaireLoad(orderId, itemId, sellerId, experienceId);
        }

        this.viewedAt = new Date().toISOString();

        initializeLocalize();
    }

    handleSubmit = async (waiver, form) => {
        form.setSubmitting(true);
        await this.props.waiverSave(this.props.template, waiver);
        form.setSubmitting(false);
    };

    getInitialWaiverValues() {
        const { defaultParticipantAnswers } = this.props;

        return {
            participant: { legalName: '', dateOfBirth: '', email: '', answers: defaultParticipantAnswers },
            signedFor: SIGNED_FOR_ADULT,
            minors: [],
            viewedAt: this.viewedAt,
        };
    }

    render() {
        const { template = {} } = this.props;

        if (_.isEmpty(template)) {
            return (
                <div className="text-center">
                    <Loader show />
                </div>
            );
        }

        const sellerLogo = `${process.env.REACT_APP_XOLA_API}/api/sellers/${template.seller.id}/logo`;

        return (
            <div className="TemplatePreview container">
                <Paper>
                    <div className="alert alert-warning text-center border-0 rounded-0">
                        <strong>This activity requires that all participants sign a waiver</strong>
                    </div>

                    <Form
                        validator={waiverValidator(template)}
                        initialValues={this.getInitialWaiverValues()}
                        onSubmit={this.handleSubmit}
                    >
                        <div className="p-5">
                            <div className="text-center mb-5 notranslate">
                                <img
                                    height={LOGO_SIZE}
                                    src={`${sellerLogo}?height=${LOGO_SIZE}&format=png`}
                                    alt={`${template.seller.name} Logo`}
                                />

                                <h1>{template.title}</h1>
                            </div>

                            <TemplateBodyDelta>{template.body}</TemplateBodyDelta>
                            <hr className="mb-5" />

                            {template.allowMinors ? (
                                <Fragment>
                                    <h5>Who are you signing for?</h5>

                                    <div className="row">
                                        <div className="col col-md-12 col-lg-8">
                                            <TemplatePreviewSignedForToggle />
                                        </div>

                                        {template.allowMultipleMinors ? (
                                            <div className="col col-md-12 col-lg-3">
                                                <MinorsSpinnerField />
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="alert alert-warning notranslate">{template.minorInstructions}</div>
                                    <hr className="mb-5" />
                                </Fragment>
                            ) : null}

                            <Field name="minors">
                                {({ field }) =>
                                    field.value.map((minor, index) => (
                                        <GuestFields
                                            key={index}
                                            fieldPrefix={`minors.${index}`}
                                            showSignatureField={template.minorSignatureRequired}
                                            title={
                                                template.allowMultipleMinors
                                                    ? `${getOrdinal(index + 1)} Minor`
                                                    : 'Minor'
                                            }
                                            isMinor
                                        />
                                    ))
                                }
                            </Field>

                            <Field>
                                {({ form }) => (
                                    <GuestFields
                                        title={form.values.minors.length === 0 ? 'Guest' : 'Parent / Guardian'}
                                        fieldPrefix="participant"
                                    />
                                )}
                            </Field>

                            <div className="mb-5">
                                <h5>Electronic Signature Consent</h5>
                                <Field>
                                    {({ form }) => (
                                        <>
                                            {form.errors.electronicSignatureConsent && form.submitCount > 0 ? (
                                                <small className="text-danger">
                                                    {form.errors.electronicSignatureConsent}
                                                </small>
                                            ) : null}
                                            <CustomInput
                                                type="checkbox"
                                                id="electronicSignatureConsent"
                                                onChange={e => this.handleAcceptElectronicSignatureConsent(e, form)}
                                                label={
                                                    <span className="text-muted notranslate">
                                                        {template.electronicSignatureConsent}
                                                    </span>
                                                }
                                            />
                                        </>
                                    )}
                                </Field>
                            </div>

                            <div className="text-center">
                                <Field>
                                    {({ form }) => (
                                        <SubmitButton
                                            loading={form.isSubmitting}
                                            disabled={!this.isAccepted()}
                                            type="submit"
                                            className="btn btn-success"
                                        >
                                            Finish and Agree
                                        </SubmitButton>
                                    )}
                                </Field>
                            </div>

                            <Field>
                                {({ form }) => (
                                    <div className="text-center">
                                        {!_.isEmpty(form.errors) && form.submitCount > 0 ? (
                                            <small className="text-danger">
                                                {Object.keys(form.errors).length === 1 &&
                                                form.errors.electronicSignatureConsent
                                                    ? 'Please tick mark Electronic Signature consent checkbox'
                                                    : 'Fix missing or invalid fields before continuing'}
                                            </small>
                                        ) : null}
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Form>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    template: state.templates.data[props.id],
    questionnaire: state.questionnaire,
    defaultParticipantAnswers: getQuestionnaireFields(state, false),
    acceptedBody: state.waiver.checked === getCheckboxCount(state, props.id),
});

const mapDispatchToProps = {
    templateLoad,
    questionnaireLoad,
    waiverSave,
    waiverUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreview);
