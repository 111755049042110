import waiversApi from '../api/waiversApi';
import { navigate } from '@reach/router';

export const WAIVER_SAVE_REQUEST = 'WAIVER_SAVE_REQUEST';
export const WAIVER_SAVE_SUCCESS = 'WAIVER_SAVE_SUCCESS';
export const WAIVER_SAVE_FAILURE = 'WAIVER_SAVE_FAILURE';

export const WAIVER_UPDATE_CHECKED = 'WAIVER_UPDATE_CHECKED';
export const WAIVER_UPDATE = 'WAIVER_UPDATE';

/**
 * Save a waiver.
 * @param {object} template
 * @param {object} waiver
 */
export const waiverSave = (template, waiver) => async (dispatch, getState) => {
    try {
        dispatch({ type: WAIVER_SAVE_REQUEST });
        const { orderId, itemId, tags } = getState().waiver.waiver;

        const { data } = await waiversApi.post(`/templates/${template.id}/waivers`, {
            ...waiver,
            orderId,
            itemId,
            tags,
        });

        dispatch({ type: WAIVER_SAVE_SUCCESS, waiver: data });
        navigate('/thank-you');
    } catch (e) {
        dispatch({ type: WAIVER_SAVE_FAILURE, error: e.message });
    }
};

/**
 * Update checked count from the current waiver.
 * @param {boolean} checked
 */
export const waiverUpdateChecked = checked => ({
    type: WAIVER_UPDATE_CHECKED,
    checked,
});

/**
 * Update the waiver.
 * @param {object} data
 */
export const waiverUpdate = data => ({
    type: WAIVER_UPDATE,
    data,
});
