import { SIGNED_FOR_ADULT } from './TemplatePreviewSignedForToggle';
import SpinnerButton from '../common/SpinnerButton';
import React from 'react';
import { FieldArray } from 'formik';
import { getQuestionnaireFields } from '../../selectors/questionnaireSelectors';
import { useSelector } from 'react-redux';

const MinorsSpinnerField = () => {
    const defaultMinor = useSelector(state => ({
        legalName: '',
        dateOfBirth: '',
        email: '',
        answers: getQuestionnaireFields(state, true),
    }));

    return (
        <FieldArray name="minors">
            {({ form, insert, remove }) =>
                form.values.signedFor === SIGNED_FOR_ADULT ? null : (
                    <SpinnerButton
                        value={form.values.minors.length}
                        label="Minors"
                        min={1}
                        onIncrement={() => insert(form.values.minors.length, defaultMinor)}
                        onDecrement={() => remove(form.values.minors.length - 1)}
                    />
                )
            }
        </FieldArray>
    );
};

export default MinorsSpinnerField;
