import ConfirmationButton from '../common/ConfirmationButton';
import Authorize from '../common/Authorize';
import icon from '../../images/icon.svg';
import React, { Component } from 'react';
import { Link } from '@reach/router';
import './TemplateListItem.scss';

class TemplateListItem extends Component {
    handleDelete = () => {
        this.props.onDelete(this.props.template);
    };

    render() {
        const { template } = this.props;

        return (
            <div className="TemplateListItem border-bottom p-3 d-flex align-items-center">
                <img className="mr-3" src={icon} alt="Xola Waivers Icon" />
                <h5 className="mr-auto text-truncate m-0">{template.title}</h5>

                <Authorize any={['ROLE_SELLER', 'ROLE_SELLER_ADMIN', 'ROLE_CURATOR', 'ROLE_ADMIN']}>
                    <div className="TemplateListItem-buttons text-nowrap">
                        <Link to={`/templates/${template.id}/edit`} className="btn btn-light">
                            <i className="fas fa-pencil-alt mr-1" /> Edit
                        </Link>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`/templates/${template.id}/preview?sellerId=${template.seller.id}`}
                            className="btn btn-light"
                        >
                            <i className="far fa-eye mr-1" /> Preview/Sign
                        </a>

                        <ConfirmationButton onClick={this.handleDelete} className="btn btn-light">
                            <span>
                                <i className="fas fa-trash mr-1" /> Delete
                            </span>

                            <span>
                                <i className="fas fa-trash mr-1" /> Confirm
                            </span>
                        </ConfirmationButton>
                    </div>
                </Authorize>
            </div>
        );
    }
}

export default TemplateListItem;
