import createStore from './store/createStore';
import Root from './components/Root';
import ReactDOM from 'react-dom';
import './utils/resizeObserver';
import React from 'react';

// Main stylesheet. Must be imported last.
import './styles/index.scss';

const rootElement = document.getElementById('root');
const store = createStore();

// Enable Hot Module Replacement for components.
// Will not be included on production build.
if (module.hot) {
    module.hot.accept('./components/Root', () => {
        const Root = require('./components/Root').default;
        ReactDOM.render(<Root store={store} />, rootElement);
    });
}

ReactDOM.render(<Root store={store} />, rootElement);
