import { errorDismissLatest } from '../../actions/errorsActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Errors.scss';

class Errors extends Component {
    render() {
        const { latestError, errorDismissLatest } = this.props;

        if (!latestError) {
            return null;
        }

        return (
            <div className="Errors fixed-top p-3">
                <div className="alert alert-danger alert-dismissible shadow-lg mx-auto" role="alert">
                    <strong>{latestError.title}</strong>
                    <br />
                    <small>{latestError.error}</small>

                    <button type="button" className="close" onClick={errorDismissLatest}>
                        <i className="fas fa-times" />
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    latestError: state.errors[state.errors.length - 1],
});

const mapDispatchToProps = {
    errorDismissLatest,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Errors);
