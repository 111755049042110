export const USER_TYPE_SELLER = 1;
export const USER_TYPE_TRAVELER = 2;
export const USER_TYPE_USER = 3;
export const LOCALES_FOR_TRANSLATION = ['fr', 'es', 'de'];

export const DEFAULT_QUESTIONNAIRE_LABELS = [
    'Phone',
    'Height',
    'Weight',
    'Address',
    'Medical conditions',
    'How did you hear about us?',
    'Pickup location',
];