import { LOCALES_FOR_TRANSLATION } from '../constants';

const isCurrentLocaleForTranslation = () => {
    return LOCALES_FOR_TRANSLATION.some(shortCode => navigator.language.startsWith(shortCode));
};

export const initializeLocalize = () => {
    const shouldInitializeLocalization = isCurrentLocaleForTranslation();
    const isLocalizeEnabled = process.env.REACT_APP_LOCALIZE_ENABLED;
    if (isLocalizeEnabled && shouldInitializeLocalization) {
        window.Localize.initialize({
            key: process.env.REACT_APP_LOCALIZE_API_KEY,
            rememberLanguage: true,
            blockedClasses: ['QuillDelta'],
            saveNewPhrasesFromSource: true,
        });
    }
};
