import { TEMPLATES_LOAD_FAILURE, TEMPLATE_LOAD_FAILURE, TEMPLATE_DELETE_FAILURE } from '../actions/templatesActions';
import { USER_AUTHENTICATE_FAILURE } from '../actions/userActions';
import { ERROR_DISMISS_LATEST } from '../actions/errorsActions';
import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';
import { WAIVER_SAVE_FAILURE } from '../actions/waiverActions';

export const initialState = [];

const errorsReducer = createReducer(initialState, {
    [ERROR_DISMISS_LATEST](state) {
        return _.remove(state, state.length - 1);
    },

    [USER_AUTHENTICATE_FAILURE](state, { error }) {
        state.push({
            title: 'Failed to authenticate user',
            error,
        });
    },

    [TEMPLATES_LOAD_FAILURE](state, { error }) {
        state.push({
            title: 'Failed to load templates',
            error,
        });
    },

    [TEMPLATE_LOAD_FAILURE](state, { error }) {
        state.push({
            title: 'Failed to load the template',
            error,
        });
    },

    [TEMPLATE_DELETE_FAILURE](state, { error }) {
        state.push({
            title: 'Failed to delete the template',
            error,
        });
    },

    [WAIVER_SAVE_FAILURE](state, { error }) {
        state.push({
            title: 'Failed to save the waiver',
            error,
        });
    },
});

export default errorsReducer;
