import { object, string, date, array, bool } from 'yup';
import { subYears } from 'date-fns';

const customDate = date().transform(function(value, originalValue) {
    if (value === originalValue) {
        return value; // Initial value.
    }

    if (originalValue.length !== 10) {
        return new Date(''); // Invalid date.
    }

    return new Date(originalValue);
});

const waiverValidator = template => {
    // Oldest possible birth date of an adult.
    const maxDate = subYears(new Date(), template.adultAge);
    const minDate = new Date('1900-01-01');
    const maxDateMessage = [
        'The age you’ve entered indicates that this waiver is for a minor',
        `(anyone under the age of ${template.adultAge} years old).`,
        'In order to participate, a parent or guardian must sign in addition to the minor.',
    ].join(' ');

    return object().shape({
        participant: object().shape({
            legalName: string()
                .min(2, 'Too short.')
                .max(50, 'Too long.')
                .required('Required.'),

            dateOfBirth: customDate
                .max(maxDate, (date) => {
                    if (date.value > new Date()) {
                        return 'Date of birth is in the future.'
                    }
                    return maxDateMessage;
                })
                .min(minDate, 'Invalid birth date.')
                .typeError('Invalid birth date.')
                .required('Required.'),

            email: string()
                .email('Must be a valid email.')
                .required('Required.'),
        }),

        minors: array().of(
            object().shape({
                legalName: string()
                    .min(2, 'Too short.')
                    .max(50, 'Too long.')
                    .required('Required.'),

                dateOfBirth: customDate
                    .min(maxDate, 'The age you’ve entered indicates that this waiver is for a adult.')
                    .max(new Date(), 'Date must be in the past.')
                    .typeError('Invalid date.')
                    .required('Required.'),

                email: string().email('Must be a valid email.'),

                signature: template.minorSignatureRequired ? string().required('Required.') : string(),
            }),
        ),

        signature: string().required('Required.'),
        electronicSignatureConsent: bool().required('Required.').oneOf([true], 'The consent must be accepted.'),
    });
};

export default waiverValidator;
