import xolaApi from '../api/xolaApi';
import retryAsyncFn from '../utils/asyncRetry';

export const QUESTIONNAIRE_LOAD_REQUEST = 'QUESTIONNAIRE_LOAD_REQUEST';
export const QUESTIONNAIRE_LOAD_SUCCESS = 'QUESTIONNAIRE_LOAD_SUCCESS';
export const QUESTIONNAIRE_LOAD_FAILURE = 'QUESTIONNAIRE_LOAD_FAILURE';
/**
 * Load a questionnaire.
 * @param {string} orderId
 * @param {string} itemId
 */
export const questionnaireLoad = (orderId, itemId, sellerId, experienceId) => async dispatch => {
    try {
        let path = '';
        if (orderId && itemId) {
            path = `/api/orders/${orderId}/items/${itemId}/questions`;
        }
        else if (experienceId) {
            path = `api/experiences/${experienceId}/questions`
        } else if (sellerId) {
            path = `/api/sellers/${sellerId}/questions`;
        }
        dispatch({ type: QUESTIONNAIRE_LOAD_REQUEST });
        const { data } = await retryAsyncFn(() => xolaApi.get(path));
        dispatch({ type: QUESTIONNAIRE_LOAD_SUCCESS, questionnaire: data });
    } catch (e) {
        dispatch({ type: QUESTIONNAIRE_LOAD_FAILURE, error: e.message });
    }
};
