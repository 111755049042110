import { connect, getIn } from 'formik';

const ErrorMessage = ({ name, formik }) => {
    const error = getIn(formik.errors, name);
    const isTouched = getIn(formik.touched, name);
    const isSubmitted = formik.submitCount > 0;
    return (isTouched || isSubmitted) && error ? error : null;
};

export default connect(ErrorMessage);
