import getParamsFromUrl from '../utils/getParamsFromUrl';
import { USER_TYPE_USER } from '../constants';
import waiversApi from '../api/waiversApi';
import xolaApi from '../api/xolaApi';
import _ from 'lodash';

export const USER_AUTHENTICATE_REQUEST = 'USER_AUTHENTICATE_REQUEST';
export const USER_AUTHENTICATE_SUCCESS = 'USER_AUTHENTICATE_SUCCESS';
export const USER_AUTHENTICATE_FAILURE = 'USER_AUTHENTICATE_FAILURE';

export const userAuthenticate = () => async (dispatch, getState) => {
    if (!_.isEmpty(getState().user.data)) {
        return;
    }

    try {
        dispatch({ type: USER_AUTHENTICATE_REQUEST });
        const { apiKey, seller } = getParamsFromUrl(window.location.hash);
        waiversApi.setAuthenticationParams(apiKey, seller);
        xolaApi.setAuthenticationParams(apiKey, seller);
        const { data: user } = await xolaApi.get('/api/users/me', { params: { seller } });
        const isDelegate = !_.includes(user.roles, 'ROLE_ADMIN') && user.type === USER_TYPE_USER;

        if (isDelegate) {
            const { data: delegate } = await xolaApi.get(`/api/users/${seller}/delegates/${user.id}`);
            user.delegate = delegate;
        }

        dispatch({ type: USER_AUTHENTICATE_SUCCESS, user, seller });
    } catch (e) {
        dispatch({ type: USER_AUTHENTICATE_FAILURE, error: e.message });
    }
};
