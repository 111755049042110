import axios from 'axios';

const createApiClient = config => {
    const api = axios.create(config);

    api.setAuthenticationParams = (apiKey, seller) => {
        api.interceptors.request.use(config => {
            config.headers['X-API-KEY'] = apiKey;

            if (config.method === 'get') {
                config.params = { ...config.params, seller };
            } else {
                config.data = { ...config.data, seller };
            }

            return config;
        });
    };

    return api;
};

export default createApiClient;
