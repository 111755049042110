import React, { Component } from 'react';

class SubmitButton extends Component {
    render() {
        const { loading, children, className, ...rest } = this.props;

        return (
            <button type="submit" className={`btn btn-success ${className}`} disabled={loading} {...rest}>
                {loading ? <i className="fas fa-spinner fa-spin mr-2" /> : <i className="fa fa-check mr-2" />}
                {children}
            </button>
        );
    }
}

export default SubmitButton;
