import { object, string, number } from 'yup';

const templateValidator = object().shape({
    title: string()
        .min(2, 'Too short.')
        .max(100, 'Too long.')
        .required('Required'),

    body: object().required('Required.'),
    electronicSignatureConsent: string().required('Required.'),

    adultAge: number()
        .typeError('Must be a number.')
        .required('Required.')
        .min(1, 'Must be a number between 1 and 100.')
        .max(100, 'Must be a number between 1 and 100.'),

    minorInstructions: string().when('allowMinors', { is: true, then: string().required('Required.') }),
});

export default templateValidator;
