import React, { Component } from 'react';
import classNames from 'classnames';

class Paper extends Component {
    render() {
        const { className, ...rest } = this.props;

        return (
            <div
                className={classNames('Paper border shadow bg-white rounded overflow-hidden mt-3 mb-4', className)}
                {...rest}
            >
                {this.props.children}
            </div>
        );
    }
}

export default Paper;
