import React, { createContext } from 'react';
import classNames from 'classnames';
import Delta from 'quill-delta';

const Context = createContext();

/**
 * Get style from attributes.
 */
const getStyle = (attributes = {}) => {
    const style = {};

    if (attributes.bold) {
        style.fontWeight = 'bold';
    }

    if (attributes.italic) {
        style.fontStyle = 'italic';
    }

    if (attributes.color) {
        style.color = attributes.color;
    }

    if (attributes.underline) {
        style.textDecoration = 'underline';
    }

    if (attributes.align) {
        style.textAlign = attributes.align;
    }

    // Handle more attribute types here.

    return style;
};

/**
 * Get an array of block elements from Delta object.
 * @param {Delta} delta
 */
const getBlocks = delta => {
    const blocks = [];

    new Delta(delta).eachLine((delta, attributes) => {
        blocks.push({ delta, attributes });
    });

    return blocks;
};

/**
 * Renders an insert operation.
 */
const Insert = ({ children: insert }) => {
    if (insert.variable) {
        return (
            <Context.Consumer>
                {({ renderVariable }) => (renderVariable ? renderVariable(insert.variable) : insert.variable)}
            </Context.Consumer>
        );
    }
    if(insert.image) {
        return <img className="img-fluid" alt={insert.image} src={insert.image} />;
    }
    // Handle more insert types here.

    return insert;
};

/**
 * Renders an operation.
 */
const Op = ({ children: op }) => {
    return (
        <span style={getStyle(op.attributes)}>
            <Insert>{op.insert}</Insert>
        </span>
    );
};

/**
 * Renders a Delta block.
 */
const Block = ({ attributes, children: delta }) => {
    if (delta.ops.length === 0) {
        return <br />;
    }

    let element = 'p';

    if (attributes.header) {
        element = `h${attributes.header}`;
    }

    // Handle more element types here.

    return React.createElement(
        element,
        { style: getStyle(attributes) },
        delta.ops.map((op, index) => <Op key={index}>{op}</Op>),
    );
};

const QuillDelta = ({ className, renderVariable, children = { ops: [] } }) => {
    return (
        <Context.Provider value={{ renderVariable }}>
            <div className={classNames('QuillDelta', className)}>
                {getBlocks(children).map(({ delta, attributes }, index) => (
                    <Block key={index} attributes={attributes}>
                        {delta}
                    </Block>
                ))}
            </div>
        </Context.Provider>
    );
};

export default QuillDelta;
