import { createReducer } from 'redux-starter-kit';
import {
    QUESTIONNAIRE_LOAD_SUCCESS,
    QUESTIONNAIRE_LOAD_REQUEST,
    QUESTIONNAIRE_LOAD_FAILURE
} from '../actions/questionnaireActions';

export const initialState = {
    loading: false,
    data: {},
};

const questionnaireReducer = createReducer(initialState, {
    [QUESTIONNAIRE_LOAD_REQUEST](state) {
        state.loading = true;
    },
    [QUESTIONNAIRE_LOAD_SUCCESS](state, { questionnaire }) {
        state.data = questionnaire;
        state.loading = false;
    },
    [QUESTIONNAIRE_LOAD_FAILURE](state) {
        state.loading = false;
    },
});

export default questionnaireReducer;
