import { templatesLoad, templateDelete } from '../../actions/templatesActions';
import TemplateListItem from './TemplateListItem';
import SettingsPage from '../common/SettingsPage';
import Authorize from '../common/Authorize';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import _ from 'lodash';
import Loader from '../common/Loader';

class TemplatesPage extends Component {
    componentDidMount() {
        if (_.isEmpty(this.props.templates.data)) {
            this.props.templatesLoad();
        }
    }

    handleTemplateDelete = template => {
        this.props.templateDelete(template);
    };

    render() {
        const { templates } = this.props;

        return (
            <SettingsPage>
                <div className="TemplateList">
                    <Authorize any={['ROLE_SELLER', 'ROLE_SELLER_ADMIN', 'ROLE_CURATOR', 'ROLE_ADMIN']}>
                        <div className="text-right mb-3">
                            <Link to="/templates/create" className="btn btn-success">
                                <i className="fa fa-plus mr-1" /> Create a waiver
                            </Link>
                        </div>
                    </Authorize>

                    <div className="text-center">
                        <Loader className="fa-2x" show={templates.loading} />
                    </div>

                    <Authorize
                        any={[
                            'ROLE_SELLER',
                            'ROLE_SELLER_ADMIN',
                            'ROLE_RESERVATION',
                            'ROLE_RESERVATION_LITE',
                            'ROLE_ADMIN',
                        ]}
                    >
                        {_.map(templates.data, template => (
                            <TemplateListItem
                                key={template.id}
                                template={template}
                                onDelete={this.handleTemplateDelete}
                            />
                        ))}
                    </Authorize>

                    {_.isEmpty(templates.data) && !templates.loading ? (
                        <div className="alert alert-secondary text-center">
                            You have not created any waivers templates yet. Click on the Create waiver template button
                            to get started.
                        </div>
                    ) : null}
                </div>
            </SettingsPage>
        );
    }
}

const mapStateToProps = state => ({
    templates: state.templates,
});

const mapDispatchToProps = {
    templatesLoad,
    templateDelete,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TemplatesPage);
