import { Field } from 'formik';
import React, { Component } from 'react';
import _ from 'lodash';
import ErrorMessage from '../../form/ErrorMessage';
import { DEFAULT_QUESTIONNAIRE_LABELS } from '../../../constants';

class TextAreaField extends Component {
    id = _.uniqueId('TextAreaField');
    render() {
        const { fieldPrefix, field } = this.props;
        const isDefaultQuestionnaire = DEFAULT_QUESTIONNAIRE_LABELS.includes(field.label);
        return (
            <div className="form-group">
                <label
                    htmlFor={`${this.id}-${field.label}`}
                    className={`${isDefaultQuestionnaire ? '' : 'notranslate'}`}
                >
                    {field.label}
                </label>
                <Field
                    as="textarea"
                    className="QuestionnaireFields-field form-control"
                    id={`${this.id}-${field.label}`}
                    name={`${fieldPrefix}.value`}
                    placeholder={field.required ? 'Required' : ''}
                    validate={value => {
                        if (field.required && !value) {
                            return 'Required.';
                        }
                    }}
                />
                <small className="text-danger">
                    <ErrorMessage name={`${fieldPrefix}.value`} />
                </small>
            </div>
        );
    }
}

export default TextAreaField;
