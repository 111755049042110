import { Field, ErrorMessage } from 'formik';
import React, { Component } from 'react';
import _ from 'lodash';

class CheckboxField extends Component {
    id = _.uniqueId('CheckboxField');

    render() {
        const { fieldPrefix, field } = this.props;
        return (
            <div className="form-check">
                <Field
                    type="checkbox"
                    className="form-check-input"
                    id={`${this.id}-${field.label}`}
                    name={`${fieldPrefix}.value`}
                    placeholder={field.required ? 'Required' : ''}
                    validate={value => {
                        if (field.required && !value) {
                            return 'Required.';
                        }
                    }}
                />
                {field.label !== 'Opt in' ? (
                    <label htmlFor={`${this.id}-${field.label}`} className="form-check-label notranslate">
                        {field.label}
                    </label>
                ) : null}
                <div className="notranslate">
                    {field.description ? (
                        field.url ? (
                            <small className="d-block text-muted">
                                {' '}
                                <a href={field.url}>{field.description}</a>
                            </small>
                        ) : (
                            <small className="d-block text-muted">{field.description}</small>
                        )
                    ) : null}
                </div>

                <small className="text-danger">
                    <ErrorMessage name={`${fieldPrefix}.${field.label}}`} />
                </small>
            </div>
        );
    }
}

export default CheckboxField;
