import questionnaireReducer from '../reducers/questionnaireReducer';
import bootstrapReducer from '../reducers/bootstrapReducer';
import templatesReducer from '../reducers/templatesReducer';
import errorsReducer from '../reducers/errorsReducers';
import waiverReducer from '../reducers/waiverReducer';
import { configureStore } from 'redux-starter-kit';
import userReducer from '../reducers/userReducer';

const rootReducer = {
    bootstrap: bootstrapReducer,
    templates: templatesReducer,
    errors: errorsReducer,
    user: userReducer,
    waiver: waiverReducer,
    questionnaire: questionnaireReducer,
};

const createStore = (preloadedState = {}) => {
    return configureStore({
        preloadedState,
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
    });
};

export default createStore;
