import { Field } from 'formik';
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getQuestionnaireFields } from '../../selectors/questionnaireSelectors';

export const SIGNED_FOR_ADULT = 'ADULT';
export const SIGNED_FOR_MINORS = 'MINORS';
export const SIGNED_FOR_ADULT_AND_MINORS = 'ADULT_AND_MINORS';

const TemplatePreviewSignedForToggle = () => {
    const defaultMinor = useSelector(state => ({
        legalName: '',
        dateOfBirth: '',
        email: '',
        answers: getQuestionnaireFields(state, true),
    }));

    const defaultParticipantAnswers = useSelector(state => {
        return getQuestionnaireFields(state, false);
    });

    return (
        <Field name="signedFor">
            {({ field, form }) => (
                <div className="row">
                    <div className="col col-sm-12 col-md-12 col-lg-4">
                        <button
                            type="button"
                            onClick={() => {
                                form.setFieldValue('signedFor', SIGNED_FOR_ADULT);
                                form.setFieldValue('participant.answers', defaultParticipantAnswers);
                                form.setFieldValue('minors', []);
                            }}
                            className={classNames('btn btn-sm btn-block mb-3 text-truncate', {
                                'btn-primary': field.value === SIGNED_FOR_ADULT,
                                'btn-outline-secondary': field.value !== SIGNED_FOR_ADULT,
                            })}
                        >
                            Adult
                        </button>
                    </div>

                    <div className="col col-sm-12 col-md-12 col-lg-4">
                        <button
                            type="button"
                            onClick={() => {
                                form.setFieldValue('signedFor', SIGNED_FOR_MINORS);
                                form.setFieldValue('participant.answers', []);

                                if (form.values.minors.length === 0) {
                                    form.setFieldValue('minors', [defaultMinor]);
                                }
                            }}
                            className={classNames('btn btn-sm btn-block mb-3 text-truncate', {
                                'btn-primary': field.value === SIGNED_FOR_MINORS,
                                'btn-outline-secondary': field.value !== SIGNED_FOR_MINORS,
                            })}
                        >
                            Minor(s)
                        </button>
                    </div>

                    <div className="col col-sm-12 col-md-12 col-lg-4">
                        <button
                            type="button"
                            onClick={() => {
                                form.setFieldValue('signedFor', SIGNED_FOR_ADULT_AND_MINORS);
                                form.setFieldValue('participant.answers', defaultParticipantAnswers);

                                if (form.values.minors.length === 0) {
                                    form.setFieldValue('minors', [defaultMinor]);
                                }
                            }}
                            className={classNames('btn btn-sm btn-block mb-3 text-truncate', {
                                'btn-primary': field.value === SIGNED_FOR_ADULT_AND_MINORS,
                                'btn-outline-secondary': field.value !== SIGNED_FOR_ADULT_AND_MINORS,
                            })}
                        >
                            Adult and Minor(s)
                        </button>
                    </div>
                </div>
            )}
        </Field>
    );
};

export default TemplatePreviewSignedForToggle;
