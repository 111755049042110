import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

import {
    WAIVER_UPDATE_CHECKED,
    WAIVER_SAVE_REQUEST,
    WAIVER_SAVE_SUCCESS,
    WAIVER_UPDATE,
} from '../actions/waiverActions';

export const initialState = {
    /**
     * Is the waiver currently being saved?
     */
    saving: false,

    /**
     * Current waiver.
     */
    waiver: {},

    /**
     * Number of checked checkboxes from the waiver template.
     */
    checked: 0,
};

const waiverReducer = createReducer(initialState, {
    [WAIVER_SAVE_REQUEST](state) {
        state.saving = true;
    },

    [WAIVER_SAVE_SUCCESS](state, { waiver }) {
        state.waiver = waiver;
        state.saving = false;
        state.checked = 0;
    },

    [WAIVER_UPDATE_CHECKED](state, { checked }) {
        state.checked += checked ? 1 : -1;
    },

    [WAIVER_UPDATE](state, { data }) {
        _.assign(state.waiver, data);
    },
});

export default waiverReducer;
