import { delay } from 'lodash';

const retryAsyncFn = (fn, retries = 3, maxDelay = 1000) => {
    return new Promise((resolve, reject) => {
        const attempt = async () => {
            try {
                const result = await fn();
                resolve(result);
            } catch (error) {
                if (retries === 0) {
                    reject(error);
                } else {
                    retries--;
                    delay(attempt, Math.random() * maxDelay);
                }
            }
        };
        attempt(); // Initial attempt
    });
};

export default retryAsyncFn;
