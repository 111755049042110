import EditorField from '../form/EditorField';
import React, { Component } from 'react';
import { Quill } from 'react-quill';
import './TemplateBodyField.scss';
import _ from 'lodash';

const VARIABLES = {
    '{requiredCheckbox}': 'Required Checkbox',
    '{currentDate}': 'Current Date',
};

const Embed = Quill.import('blots/embed');

class VariableEmbed extends Embed {
    static blotName = 'variable';
    static tagName = 'span';
    static className = 'TemplateBodyFieldVariable';

    static create(value) {
        let node = super.create();
        node.textContent = value;
        return node;
    }

    static value(node) {
        return node.textContent;
    }
}

Quill.register('formats/variable', VariableEmbed);

class TemplateBodyField extends Component {
    insertVariable(value) {
        const cursorPosition = this.quill.getSelection().index;
        this.quill.insertEmbed(cursorPosition, 'variable', value);
        this.quill.setSelection(cursorPosition + 1);
    }

    render() {
        const { name, label } = this.props;

        return (
            <div className="TemplateBodyField">
                <EditorField
                    name={name}
                    label={label}
                    toolbar={
                        <div id="toolbar">
                            <select className="ql-header" />
                            <button className="ql-bold" />
                            <button className="ql-italic" />
                            <button className="ql-underline" />
                            <select className="ql-color" />
                            <select className="ql-align" />

                            <select className="ql-variable">
                                {_.map(VARIABLES, (label, value) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    }
                    modules={{
                        toolbar: {
                            container: '#toolbar',
                            handlers: {
                                variable: this.insertVariable,
                            },
                        },
                    }}
                />
            </div>
        );
    }
}

export default TemplateBodyField;
