import { Field } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import ErrorMessage from '../form/ErrorMessage';
import SignatureModal from '../modals/SignatureModal';
import QuestionnaireForm from '../questionnaire/QuestionnaireForm';
import DateOfBirthField from './DateOfBirthField';
import './GuestFields.scss';

const GuestFields = ({ title, fieldPrefix, isMinor, showSignatureField = true }) => {
    const [openSignatureModal, setOpenSignatureModal] = useState(false);
    const isQuestionnaireLoading = useSelector(state => state.questionnaire.loading);

    const getFieldName = fieldName => {
        return `${fieldPrefix}.${fieldName}`;
    };

    const getId = id => {
        return isMinor ? `${fieldPrefix}.${id}` : id;
    };

    const signatureFieldName = isMinor ? getFieldName('signature') : 'signature';

    return (
        <div className="GuestFields">
            <h5>{title} Information</h5>

            <div className="GuestFields-field form-group">
                <label htmlFor={getId('legalName')}>Full Legal Name</label>

                <Field
                    type="text"
                    className="form-control"
                    id={getId('legalName')}
                    name={getFieldName('legalName')}
                    placeholder="Required"
                />

                <small className="text-danger">
                    <ErrorMessage name={getFieldName('legalName')} />
                </small>
            </div>

            <div className="GuestFields-field form-group">
                <DateOfBirthField fieldPrefix={fieldPrefix} />
            </div>

            <div className="GuestFields-field form-group">
                <label htmlFor={getId('email')}>Email</label>

                <Field
                    name={getFieldName('email')}
                    type="text"
                    className="form-control"
                    id={getId('email')}
                    placeholder={isMinor ? "Optional" : "Required"}
                />

                <small className="text-danger">
                    <ErrorMessage name={getFieldName('email')} />
                </small>
            </div>

            <Field name={getFieldName('answers')}>
                {({ form }) => (
                    <div className="GuestFields-field">
                        {isQuestionnaireLoading ? (
                            <Spinner size="sm" color="secondary" className="mb-3" />
                        ) : (
                            <QuestionnaireForm
                                fieldPrefix={getFieldName('answers')}
                                signedFor={form.values.signedFor}
                                isMinor={isMinor}
                            />
                        )}
                    </div>
                )}
            </Field>

            {showSignatureField ? (
                <Field name={isMinor ? getFieldName('signature') : 'signature'}>
                    {({ field, form }) => (
                        <div className="row mb-5">
                            <div className="col-lg-4">
                                <h5>{title} Signature</h5>

                                <button
                                    type="button"
                                    className="btn btn-primary btn-block"
                                    onClick={() => setOpenSignatureModal(true)}
                                >
                                    {field.value ? 'Edit' : 'Add'} Signature
                                </button>

                                <small className="text-danger">
                                    <ErrorMessage name={isMinor ? getFieldName('signature') : 'signature'} />
                                </small>

                                <SignatureModal
                                    title={`${title} Signature`}
                                    isOpen={openSignatureModal}
                                    toggle={() => setOpenSignatureModal(false)}
                                    onAccept={signature => {
                                        setOpenSignatureModal(false);
                                        form.setFieldValue(signatureFieldName, signature);
                                    }}
                                />

                                {field.value ? (
                                    <img
                                        className="img-fluid mt-5"
                                        src={process.env.REACT_APP_WAIVERS_API + '/' + field.value}
                                        alt="Signature"
                                    />
                                ) : null}
                            </div>
                        </div>
                    )}
                </Field>
            ) : null}
        </div>
    );
};

export default GuestFields;
