import React, { Component } from 'react';

class SpinnerButton extends Component {
    static defaultProps = {
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER,
        onChange: () => {},
        onIncrement: () => {},
        onDecrement: () => {},
    };

    isValid(value) {
        const { min, max } = this.props;
        return value >= min && value <= max;
    }

    handleDecrementClick = () => {
        const { value, onDecrement, onChange } = this.props;
        const nextValue = value - 1;

        if (this.isValid(nextValue)) {
            onDecrement(nextValue);
            onChange(nextValue);
        }
    };

    handleIncrementClick = () => {
        const { value, onIncrement, onChange } = this.props;
        const nextValue = value + 1;

        if (this.isValid(nextValue)) {
            onIncrement(nextValue);
            onChange(nextValue);
        }
    };

    render() {
        const { label, value } = this.props;

        return (
            <div className="d-flex mb-3 align-items-center">
                <button type="button" onClick={this.handleDecrementClick} className="btn btn-sm btn-outline-secondary">
                    <span className="fa fa-minus" />
                </button>

                <label className="mx-3 mb-0">{value}</label>

                <button type="button" onClick={this.handleIncrementClick} className="btn btn-sm btn-outline-secondary">
                    <span className="fa fa-plus" />
                </button>

                <label className="ml-3 mb-0">{label}</label>
            </div>
        );
    }
}

export default SpinnerButton;
