import React, { Component } from 'react';
import classNames from 'classnames';
import Field from './Field';

class TextField extends Component {
    render() {
        const { name, label, required, ...rest } = this.props;

        return (
            <Field name={name} label={label} required={required} defaultValue={''}>
                {({ field, error }) => (
                    <input
                        {...field}
                        type="text"
                        className={classNames({ 'form-control': true, 'is-invalid': error })}
                        {...rest}
                    />
                )}
            </Field>
        );
    }
}

export default TextField;
