import React, { Component } from 'react';

class ConfirmationButton extends Component {
    state = { clicks: 0 };

    handleClick = e => {
        const nextClicks = this.state.clicks + 1;

        if (nextClicks < this.props.children.length) {
            this.updateClicks(nextClicks);
        } else {
            clearInterval(this.interval);
            this.props.onClick(e);
        }
    };

    updateClicks(clicks) {
        clearInterval(this.interval);

        this.setState({ clicks }, () => {
            this.interval = setTimeout(() => {
                this.setState({ clicks: 0 });
            }, 3000);
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { children, ...rest } = this.props;

        return (
            <button {...rest} onClick={this.handleClick}>
                {children[this.state.clicks]}
            </button>
        );
    }
}

export default ConfirmationButton;
