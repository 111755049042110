import { connect } from 'react-redux';
import { Component } from 'react';
import _ from 'lodash';

class Authorize extends Component {
    check() {
        const { roles = [], permissions = [], any = [] } = this.props;

        if (any.some(role => _.includes(roles, role))) {
            return true;
        }

        return any.some(permission => _.includes(permissions.map(({ name }) => name), permission));
    }

    render() {
        if (this.check()) {
            return this.props.children;
        }

        return null;
    }
}

const mapStateToProps = state => ({
    roles: state.user.data.roles,
    permissions: state.user.data.delegate ? state.user.data.delegate.permissions : [],
});

export default connect(mapStateToProps)(Authorize);
