import { createReducer } from 'redux-starter-kit';

export const initialState = {
    /**
     * Keeps track of the original URL, even if the URL changes later.
     * Used for the "Share Waiver" feature.
     */
    entryUrl: window.location.href,
};

const bootstrapReducer = createReducer(initialState, {
    //
});

export default bootstrapReducer;
