import React, { Component } from 'react';
import classNames from 'classnames';
import Quill from 'react-quill';
import Field from './Field';

import 'react-quill/dist/quill.snow.css';
import './EditorField.scss';

class EditorField extends Component {
    render() {
        const { name, label, required, editorRef, toolbar, ...rest } = this.props;

        return (
            <Field name={name} label={label} required={required} defaultValue={{ ops: [] }}>
                {({ field, form, error }) => (
                    <div className={classNames({ 'border border-danger rounded-sm': error })}>
                        {toolbar}

                        <Quill
                            ref={editorRef}
                            value={field.value}
                            onBlur={(range, source, editor) => {
                                field.onBlur({ target: { name: field.name, value: editor.getContents() } });
                            }}
                            onChange={(value, delta, source, editor) => {
                                form.setFieldValue(field.name, editor.getContents(), true);
                            }}
                            {...rest}
                        />
                    </div>
                )}
            </Field>
        );
    }
}

export default EditorField;
