import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import copy from 'copy-to-clipboard';
import Paper from '../common/Paper';
import { Button } from 'reactstrap';
import _ from 'lodash';

class ThankYou extends Component {
    state = { showTooltip: false };
    tooltipId = _.uniqueId('ThankYou');

    handleShareClick = () => {
        copy(this.props.entryUrl);
        this.setState({ showTooltip: true });
        this.timeout = setTimeout(() => this.setState({ showTooltip: false }), 2000);
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const { waiver = {}, entryUrl } = this.props;
        const { participant = {} } = waiver;

        return (
            <div className="container">
                <Paper>
                    <div className="border-bottom p-3">
                        <h5 className="text-center m-0">Waiver Complete</h5>
                    </div>

                    <div className="p-4">
                        <p>
                            <strong className="text-uppercase">
                                Thanks for Signing. A copy of your waiver has been sent to {participant.email}
                            </strong>
                        </p>

                        <p className="mb-4">
                            Please share this waiver with the rest of your group and ensure that everyone{' '}
                            <strong>signs before the experience</strong>.
                        </p>

                        <div className="row">
                            <div className="col-xl-3 col-lg-4">
                                <a href={entryUrl} className="btn btn-block btn-success mb-3">
                                    Sign Another Waiver
                                </a>
                            </div>

                            <div className="col-xl-3 col-lg-4">
                                <button
                                    id={this.tooltipId}
                                    onClick={this.handleShareClick}
                                    className="btn btn-block btn-outline-secondary"
                                >
                                    <i className="fa fa-share-square" /> Share Waiver
                                </button>

                                <Tooltip target={this.tooltipId} isOpen={this.state.showTooltip}>
                                    Copied!
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <Button
                        className="rounded-0 border-top"
                        color="light"
                        onClick={() => window.close()}
                        disabled={!window.opener}
                        block
                    >
                        {window.opener ? 'Close' : 'You can close this window'}
                    </Button>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    waiver: state.waiver.waiver,
    entryUrl: state.bootstrap.entryUrl,
});

export default connect(mapStateToProps)(ThankYou);
