import { USER_AUTHENTICATE_SUCCESS } from '../actions/userActions';
import { createReducer } from 'redux-starter-kit';

export const initialState = {
    loading: false,
    data: {},
    seller: null,
};

const userReducer = createReducer(initialState, {
    [USER_AUTHENTICATE_SUCCESS](state, { user, seller }) {
        state.data = user;
        state.seller = seller;
    },
});

export default userReducer;
