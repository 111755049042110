import { userAuthenticate } from '../../actions/userActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class SettingsPage extends Component {
    componentDidMount() {
        this.props.userAuthenticate();
    }

    render() {
        // Added `overflow-hidden` class to prevent horizontal scrollbar from appearing.
        // That is caused by using the grid without using the container class.
        return (
            <div className="SettingsPage bg-white min-vh-100 overflow-hidden pt-1 pr-1 pb-1">{this.props.children}</div>
        );
    }
}

const mapDispatchToProps = {
    userAuthenticate,
};

export default connect(
    null,
    mapDispatchToProps,
)(SettingsPage);
