import waiversApi from '../api/waiversApi';

export const TEMPLATES_LOAD_REQUEST = 'TEMPLATES_LOAD_REQUEST';
export const TEMPLATES_LOAD_SUCCESS = 'TEMPLATES_LOAD_SUCCESS';
export const TEMPLATES_LOAD_FAILURE = 'TEMPLATES_LOAD_FAILURE';

export const TEMPLATE_LOAD_REQUEST = 'TEMPLATE_LOAD_REQUEST';
export const TEMPLATE_LOAD_SUCCESS = 'TEMPLATE_LOAD_SUCCESS';
export const TEMPLATE_LOAD_FAILURE = 'TEMPLATE_LOAD_FAILURE';

export const TEMPLATE_SAVE_REQUEST = 'TEMPLATE_SAVE_REQUEST';
export const TEMPLATE_SAVE_SUCCESS = 'TEMPLATE_SAVE_SUCCESS';
export const TEMPLATE_SAVE_FAILURE = 'TEMPLATE_SAVE_FAILURE';

export const TEMPLATE_DELETE_REQUEST = 'TEMPLATE_DELETE_REQUEST';
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS';
export const TEMPLATE_DELETE_FAILURE = 'TEMPLATE_DELETE_FAILURE';

/**
 * Load a template.
 * @param {string} id
 */
export const templateLoad = id => async dispatch => {
    try {
        dispatch({ type: TEMPLATE_LOAD_REQUEST });
        const { data } = await waiversApi.get(`/templates/${id}`);
        dispatch({ type: TEMPLATE_LOAD_SUCCESS, template: data });
    } catch (e) {
        dispatch({ type: TEMPLATE_LOAD_FAILURE, error: e.message });
    }
};

/**
 * Load all templates.
 */
export const templatesLoad = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TEMPLATES_LOAD_REQUEST });
        const { user } = getState();
        const { data } = await waiversApi.get('/templates', { seller: user.seller });
        dispatch({ type: TEMPLATES_LOAD_SUCCESS, templates: data.data });
    } catch (e) {
        dispatch({ type: TEMPLATES_LOAD_FAILURE, error: e.message });
    }
};

/**
 * Save a template.
 * @param {object} template
 */
export const templateSave = template => async (dispatch, getState) => {
    try {
        dispatch({ type: TEMPLATE_SAVE_REQUEST });
        const { user } = getState();
        const payload = { ...template, seller: user.seller };

        const { data } = template.id
            ? await waiversApi.put(`/templates/${template.id}`, payload)
            : await waiversApi.post('/templates', payload);

        dispatch({ type: TEMPLATE_SAVE_SUCCESS, template: data });
        return data;
    } catch (e) {
        dispatch({ type: TEMPLATE_SAVE_FAILURE, error: e.message });
    }
};

/**
 * Delete a template.
 * @param {object} template
 */
export const templateDelete = template => async dispatch => {
    try {
        dispatch({ type: TEMPLATE_DELETE_REQUEST });
        await waiversApi.delete(`/templates/${template.id}`);
        dispatch({ type: TEMPLATE_DELETE_SUCCESS, id: template.id });
    } catch (e) {
        dispatch({ type: TEMPLATE_DELETE_FAILURE, error: e.message });
    }
};
