import React, { Component } from 'react';
import classNames from 'classnames';
import Field from './Field';
import _ from 'lodash';

class CheckboxField extends Component {
    id = _.uniqueId('CheckboxField');

    render() {
        const { name, label, children, required, ...rest } = this.props;

        return (
            <Field name={name} label={label} required={required} defaultValue={false} alignLabel>
                {({ field, error }) => (
                    <div className="custom-control custom-checkbox" {...rest}>
                        <input
                            type="checkbox"
                            className={classNames({ 'custom-control-input': true, 'is-invalid': error })}
                            id={this.id}
                            {...field}
                            checked={field.value}
                        />

                        <label className="custom-control-label" htmlFor={this.id}>
                            {children}
                        </label>
                    </div>
                )}
            </Field>
        );
    }
}

export default CheckboxField;
