import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

import {
    TEMPLATE_DELETE_SUCCESS,
    TEMPLATES_LOAD_SUCCESS,
    TEMPLATE_LOAD_SUCCESS,
    TEMPLATE_SAVE_SUCCESS,
    TEMPLATES_LOAD_REQUEST,
} from '../actions/templatesActions';

export const initialState = {
    loading: false,
    data: {},
};

const templatesReducer = createReducer(initialState, {
    [TEMPLATES_LOAD_REQUEST](state) {
        state.loading = true;
    },

    [TEMPLATES_LOAD_SUCCESS](state, { templates }) {
        state.data = _.mapKeys(templates, template => template.id);
        state.loading = false;
    },

    [TEMPLATE_LOAD_SUCCESS](state, { template }) {
        state.data[template.id] = template;
    },

    [TEMPLATE_SAVE_SUCCESS](state, { template }) {
        state.data[template.id] = template;
    },

    [TEMPLATE_DELETE_SUCCESS](state, { id }) {
        state.data = _.omit(state.data, id);
    },
});

export default templatesReducer;
