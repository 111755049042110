import templateValidator from '../../validators/templateValidator';
import { templateSave } from '../../actions/templatesActions';
import RadioGroupField from '../form/RadioGroupField';
import TemplateBodyField from './TemplateBodyField';
import React, { Component, Fragment } from 'react';
import CheckboxField from '../form/CheckboxField';
import TextareaField from '../form/TextareaField';
import SettingsPage from '../common/SettingsPage';
import SubmitButton from '../form/SubmitButton';
import { navigate, Link } from '@reach/router';
import { Field as FormikField } from 'formik';
import TextField from '../form/TextField';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Field from '../form/Field';
import Form from '../form/Form';

const DEFAULT_ESC =
    'By checking here, you are consenting to the use of your electronic signature in lieu of an original signature on paper. You have the right to request that you sign a paper copy instead. By checking here, you are waiving that right. After consent, you may, upon written request to us, obtain a paper copy of an electronic record. No fee will be charged for such copy and no special hardware or software is required to view it. Your agreement to use an electronic signature with us for any documents will continue until such time as you notify us in writing that you no longer wish to use an electronic signature. There is no penalty for withdrawing your consent. You should always make sure that we have a current email address in order to contact you regarding any changes, if necessary.';

const DEFAULT_MINOR_INSTRUCTIONS = 'Parents / Guardians are required to sign for minors.';

class TemplateForm extends Component {
    state = { saving: false, error: false };

    handleSubmit = async template => {
        try {
            this.setState({ saving: true, error: false });
            await this.props.templateSave(template);
            navigate('/');
        } catch (e) {
            this.setState({ saving: false, error: true });
        }
    };

    getInitialValues() {
        return {
            electronicSignatureConsent: DEFAULT_ESC,
            adultAge: 18,
            allowMinors: false,
            minorInstructions: DEFAULT_MINOR_INSTRUCTIONS,
            allowMultipleMinors: false,
            minorSignatureRequired: false,
            ...this.props.template,
        };
    }

    render() {
        const { template } = this.props;

        return (
            <SettingsPage>
                <div className="TemplateForm">
                    <h4 className="text-uppercase mt-4 mb-5">
                        <Link to="/">Templates</Link> / {template.id ? 'Edit' : 'New'} Template
                    </h4>

                    <Form
                        validator={templateValidator}
                        initialValues={this.getInitialValues()}
                        onSubmit={this.handleSubmit}
                    >
                        <TextField label="Title" name="title" />
                        <TemplateBodyField label="Body" name="body" />

                        <TextareaField
                            rows={11}
                            label="Electronic Signature Consent"
                            name="electronicSignatureConsent"
                        />

                        <h4 className="text-uppercase mt-4 mb-5">Minors</h4>

                        <Field label="Minor" name="adultAge">
                            {({ field, error }) => (
                                <div className="form-inline">
                                    <span>Anyone younger than</span>

                                    <input
                                        type="text"
                                        style={{ width: 60 }}
                                        className={classNames('form-control text-center mx-2', { 'is-invalid': error })}
                                        {...field}
                                    />

                                    <span>is considered a minor</span>
                                </div>
                            )}
                        </Field>

                        <CheckboxField label="Minor Allowance" name="allowMinors">
                            Allow minors on this waiver
                        </CheckboxField>

                        <FormikField name="allowMinors">
                            {({ field }) =>
                                field.value ? (
                                    <Fragment>
                                        <TextareaField rows={3} label="Minor Instructions" name="minorInstructions" />

                                        <RadioGroupField
                                            label="Waiver Requirements"
                                            name="allowMultipleMinors"
                                            options={[
                                                { value: false, label: 'Require one waiver per minor' },
                                                { value: true, label: 'Allow multiple minors on the same waiver' },
                                            ]}
                                        />

                                        <RadioGroupField
                                            label="Parent / Guardian Rules"
                                            name="minorSignatureRequired"
                                            options={[
                                                {
                                                    value: false,
                                                    label: 'Only the parent / guardian must sign a waiver',
                                                },
                                                {
                                                    value: true,
                                                    label:
                                                        'Minors must sign the waiver in addition to parent / guardian',
                                                },
                                            ]}
                                        />
                                    </Fragment>
                                ) : null
                            }
                        </FormikField>

                        <div style={{ marginLeft: 160 }}>
                            {this.state.error ? <div className="alert alert-danger">Saving Failed</div> : null}
                            <SubmitButton loading={this.state.saving}>Save Template</SubmitButton>
                        </div>
                    </Form>
                </div>
            </SettingsPage>
        );
    }
}

const mapStateToProps = (state, props) => ({
    template: state.templates.data[props.id] || {},
});

const mapDispatchToProps = {
    templateSave,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TemplateForm);
