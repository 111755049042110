import React, { Component } from 'react';
import Field from './Field';
import _ from 'lodash';

class RadioGroupField extends Component {
    id = _.uniqueId('RadioGroupField');

    render() {
        const { name, label, options, defaultValue, required } = this.props;

        return (
            <Field name={name} label={label} required={required} defaultValue={defaultValue} alignLabel>
                {({ field, form }) => (
                    <div className="d-flex">
                        {options.map(({ label, value }, index) => (
                            <div key={index} className="form-check flex-fill">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id={`${this.id}-${index}`}
                                    checked={field.value === value}
                                    onChange={() => form.setFieldValue(name, value)}
                                />

                                <label className="form-check-label" htmlFor={`${this.id}-${index}`}>
                                    {label}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </Field>
        );
    }
}

export default RadioGroupField;
